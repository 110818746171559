<template>
  <div>
    <modal-formulario-usuario
      :idConta="idConta"
      @usuario-cadastrado="usuarioCadastrado"
    ></modal-formulario-usuario>

    <b-table hover small show-empty :fields="cabecalho" :items="usuarios">
      <template #empty>
        <p class="text-center mb-0">Nenhum registro encontrado.</p>
      </template>

      <template #cell(role)="data">
        {{ data.value == "administrator" ? "Administrador" : "Agente" }}
      </template>

      <template #cell(acoes)="row">
        <modal-formulario-usuario
          :idConta="idConta"
          acao="editar"
          :usuario="row.item"
          @usuario-atualizado="usuarioAtualizado"
        ></modal-formulario-usuario>

        <b-button
          variant="light-info"
          size="sm"
          class="btn-icon"
          v-b-tooltip.noninteractive
          title="excluir"
          @click.prevent="
            excluir(row.index, row.item.id, row.item.account_id, idConta)
          "
        >
          <i class="fas fa-trash"></i>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import ModalFormularioUsuario from "./ModalFormularioUsuario";

export default {
  name: "modal-usuarios-live-chat",
  props: {
    idConta: {
      type: Number,
      default: null
    }
  },

  components: {
    ModalFormularioUsuario
  },

  data() {
    return {
      usuarios: []
    };
  },

  mounted() {
    this.abrir();
  },

  methods: {
    abrir() {
      ApiService.get(`conta/live-chat/listar-agentes/${this.idConta}`).then(
        (response) => {
          this.usuarios = response.data.data;
        }
      );
    },

    usuarioCadastrado(usuarioCadastrado) {
      const index = this.usuarios.length;

      this.usuarios.splice(index, 0, usuarioCadastrado);
    },

    usuarioAtualizado(usuarioAtualizado) {
      const index = this.usuarios.findIndex(
        (usuario) => usuario.id === usuarioAtualizado.id
      );

      this.usuarios.splice(index, 1, usuarioAtualizado);
    },

    excluir(index, idUsuarioChatwoot, idContaChatwoot, idConta) {
      const self = this;

      Swal.fire({
        title: "Deseja realmente excluir este usuário?",
        showCancelButton: true,
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.get(
            `conta/live-chat/excluir-agente/${idConta}/${idUsuarioChatwoot}/${idContaChatwoot}`
          ).then(() => {
            Swal.fire({
              title: "MENSAGEM",
              text: "Usuário excluído com sucesso!",
              icon: "success",
              heightAuto: false
            });

            self.usuarios.splice(index, 1);
          });
        }
      });
    }
  },

  computed: {
    cabecalho() {
      const cabecalho = [
        {
          key: "id",
          label: "ID",
          sortable: true,
          class: "align-middle"
        },
        {
          key: "name",
          label: "Nome",
          sortable: true,
          class: "align-middle"
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          class: "align-middle"
        },
        {
          key: "role",
          label: "Grupo",
          sortable: true,
          class: "align-middle"
        },
        {
          key: "acoes",
          label: "Ações",
          sortable: false,
          class: "align-middle text-center"
        }
      ];

      return cabecalho;
    }
  }
};
</script>
