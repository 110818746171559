<template>
  <div :class="{ 'd-inline-flex align-middle mr-2': !botaoDropdown }">
    <template v-if="tipoBotao === 'texto'">
      <button
        type="button"
        class="btn btn-sm btn-warning font-weight-bold"
        @click.prevent="abrir"
      >
        <i class="fa fa-upload"></i>Enviar Manual
      </button>
    </template>
    <template v-else-if="tipoBotao == 'icone'">
      <b-button
        :variant="variant"
        size="sm"
        class="btn-icon"
        v-b-tooltip.noninteractive
        title="Enviar Manual"
        @click="abrir"
      >
        <i class="fas fa-file-upload"></i>
      </b-button>
    </template>
    <template v-else>
      <b-dropdown-item @click.prevent="abrir">Enviar Manual</b-dropdown-item>
    </template>

    <b-modal
      ref="mdlEnviarManual"
      title="Enviar Manual"
      @hidden="limpar"
      @shown="validacao"
      @ok="enviar"
      ok-title="Enviar"
      ok-variant="success"
      cancel-title="Cancelar"
      scrollable
      size="lg"
      ignore-enforce-focus-selector=".swal2-container"
    >
      <b-form ref="frmEnviarManual">
        <b-form-group>
          <label>Email para envio <span class="text-danger">*</span>:</label>
          <b-form-input
            name="email"
            v-model="frmEnviarManual.email"
          ></b-form-input>
        </b-form-group>
        <div
          v-html="frmEnviarManual.manual"
          class="bg-success-o-40 p-4 rounded"
        ></div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import { mapGetters } from "vuex";

export default {
  props: {
    conta: Object,
    variant: {
      type: String,
      default: "light-primary"
    },
    tipoBotao: {
      type: String,
      default: "icone"
    }
  },
  data() {
    return {
      frmEnviarManual: {
        id: null,
        email: "",
        manual: ""
      }
    };
  },
  methods: {
    limpar() {
      this.frmEnviarManual = {
        id: null,
        email: "",
        manual: ""
      };
    },

    abrir() {
      this.limpar();

      this.frmEnviarManual.id = this.conta.id;

      ApiService.post("conta/manual", { idConta: this.conta.id }).then(
        (response) => {
          this.frmEnviarManual.manual = response.data.data.manual;
          this.frmEnviarManual.email = response.data.data.email;

          this.$refs["mdlEnviarManual"].show();
        }
      );
    },

    validacao() {
      const frmEnviarManual = this.$refs["frmEnviarManual"];

      this.fv = formValidation(frmEnviarManual, {
        fields: {
          email: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    enviar(e) {
      e.preventDefault();

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("conta/enviarManual", this.frmEnviarManual)
            .then(() => {
              this.$refs["mdlEnviarManual"].hide();

              Swal.fire({
                title: "MENSAGEM",
                text: "Manual enviado com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    }
  },

  computed: {
    ...mapGetters(["validatorErrors"]),

    botaoDropdown() {
      return this.tipoBotao === "dropdown";
    }
  }
};
</script>
