<template>
  <div>
    <a href="javascript:;" class="text-success" @click="abrir"
      >Visualizar permissões dos grupos.</a
    >
    <b-modal
      ref="mdlGruposPermissoes"
      title="Permissões por Grupo"
      ok-title="Fechar"
      ok-variant="secondary"
      ok-only
      size="xl"
      scrollable
    >
      <b-table hover small :fields="cabecalho" :items="items">
        <template v-slot:[`cell(${o.grupo})`]="item" v-for="o of grupos">
          <i
            class="fas fa-check text-success"
            :key="o.grupo"
            v-if="item.value"
          ></i>
          <i class="fas fa-times text-danger" :key="o.grupo" v-else></i>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    grupos: {
      type: Array,
      default: () => []
    },
    gruposPermissoes: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    abrir() {
      this.$refs["mdlGruposPermissoes"].show();
    }
  },

  computed: {
    cabecalho() {
      const cabecalho = [
        {
          key: "permissao",
          label: "",
          class: "font-weight-bolder"
        }
      ];

      for (const obj of this.grupos) {
        cabecalho.push({
          key: obj.grupo,
          label: obj.nome_grupo,
          class: "align-middle text-center"
        });
      }

      cabecalho.sort((a, b) => {
        let fa = a.label.toLowerCase();
        let fb = b.label.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      return cabecalho;
    },

    items() {
      const linhas = [];

      for (const [chave, obj] of Object.entries(this.gruposPermissoes)) {
        const linha = {
          permissao: chave
        };

        for (const [c, o] of Object.entries(obj)) {
          linha[o.grupo] = o.permissao_ativa;
        }

        linhas.push(linha);
      }

      linhas.sort((a, b) => {
        let fa = a.permissao.toLowerCase();
        let fb = b.permissao.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      return linhas;
    }
  }
};
</script>
