<template>
  <div :class="{ 'd-inline-flex align-middle mr-2': !botaoDropdown }">
    <template v-if="tipoBotao === 'texto'">
      <button
        type="button"
        class="btn btn-sm btn-success font-weight-bold"
        @click.prevent="abrir"
      >
        <i class="fa fa-plus"></i> Cadastrar Conta
      </button>
    </template>
    <template v-else-if="tipoBotao === 'icone'">
      <b-button
        :variant="variant"
        size="sm"
        class="btn-icon"
        v-b-tooltip.noninteractive
        title="Cadastrar Conta"
        @click.prevent="abrir"
      >
        <i class="fas fa-plus"></i>
      </b-button>
    </template>
    <template v-else>
      <b-dropdown-item @click.prevent="abrir">Cadastrar Conta</b-dropdown-item>
    </template>

    <b-modal
      ref="mdlCadastrarConta"
      title="Cadastrar Conta"
      @hidden="limpar"
      @shown="validacao"
      @ok="salvar"
      ok-title="Salvar"
      ok-variant="success"
      cancel-title="Cancelar"
      scrollable
      size="lg"
      ignore-enforce-focus-selector=".swal2-container"
    >
      <b-form ref="frmCadastrarConta">
        <h6>Dados da API</h6>
        <div class="separator separator-solid separator-border-2 mb-4"></div>

        <template v-if="!contaPrincipal">
          <div class="row">
            <div class="col-md-4">
              <b-form-group>
                <label
                  >Sigla (3 caracteres)<span class="text-danger">*</span
                  >:</label
                >
                <b-form-input
                  name="sigla"
                  v-model="frmCadastrarConta.sigla"
                  autofocus
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-8">
              <b-form-group>
                <label>Login <span class="text-danger">*</span>:</label>
                <b-form-input
                  name="login"
                  v-model="frmCadastrarConta.login"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </template>
        <template v-else>
          <b-form-group>
            <label>Login <span class="text-danger">*</span>:</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  >{{ contaPrincipal.sigla }}_</span
                >
              </div>
              <b-form-input
                name="login"
                v-model="frmCadastrarConta.login"
                autofocus
              ></b-form-input>
            </div>
          </b-form-group>
        </template>

        <div class="row">
          <div class="col-md-6">
            <b-form-group>
              <label>Senha <span class="text-danger">*</span>:</label>
              <b-form-input
                name="senha"
                v-model="frmCadastrarConta.senha"
                type="password"
                autocomplete="new-password"
              ></b-form-input>
              <span class="form-text text-muted"
                >A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1
                número.</span
              >
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group>
              <label>Confirmar senha <span class="text-danger">*</span>:</label>
              <b-form-input
                name="confirmarSenha"
                v-model="frmCadastrarConta.confirmarSenha"
                type="password"
                autocomplete="new-password"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-12">
            <gerador-senha @usar-senha="usarSenha"></gerador-senha>
          </div>
        </div>

        <h6>Dados do Plano</h6>
        <div class="separator separator-solid separator-border-2 mb-4"></div>

        <div class="row">
          <div class="col-md-6">
            <b-form-group>
              <label>Tipo de plano <span class="text-danger">*</span>:</label>
              <b-form-select
                name="tipoPlano"
                :options="sltTipoPlano"
                v-model="frmCadastrarConta.tipoPlano"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6" v-show="frmCadastrarConta.tipoPlano == 'POS'">
            <b-form-group>
              <label
                >Limite mensal de SMS
                <span v-if="contaPrincipal"
                  >(máx.: {{ contaPrincipal.limiteCredito }})</span
                >
                <span class="text-danger">*</span>:</label
              >
              <b-form-input
                name="limiteCredito"
                v-model="frmCadastrarConta.limiteCredito"
                type="number"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <b-form-group>
          <div class="checkbox-inline">
            <label class="checkbox font-weight-normal">
              <input
                type="checkbox"
                name="permitirMensagensLongas"
                v-model="frmCadastrarConta.permitirMensagensLongas"
              />
              <span></span>
              Permitir mensagens longas (acima de 160 caracteres)
            </label>
          </div>
        </b-form-group>

        <div
          class="alert alert-info text-left font-size-sm"
          role="alert"
          v-show="frmCadastrarConta.tipoPlano == 'PRE'"
        >
          <i class="fas fa-info-circle text-white mr-2"></i>
          Nos planos pré-pagos é necessário adicionar créditos à conta após a
          criação da mesma. Isso poderá ser feito na tela de edição da conta.
        </div>

        <h6>Dados do Cliente</h6>
        <div class="separator separator-solid separator-border-2 mb-4"></div>

        <b-form-group>
          <div class="checkbox-inline">
            <label class="checkbox font-weight-normal">
              <input
                type="checkbox"
                name="habilitarAcessoPainel"
                v-model="frmCadastrarConta.habilitarAcessoPainel"
              />
              <span></span>
              Habilitar acesso ao Painel
            </label>
          </div>
        </b-form-group>

        <div class="row">
          <div class="col-md-6">
            <b-form-group>
              <label>Nome <span class="text-danger">*</span>:</label>
              <b-form-input
                name="nomeUsuario"
                v-model="frmCadastrarConta.nomeUsuario"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="col-md-6"
            v-show="frmCadastrarConta.habilitarAcessoPainel"
          >
            <b-form-group>
              <label>Email <span class="text-danger">*</span>:</label>
              <b-form-input
                name="emailUsuario"
                v-model="frmCadastrarConta.emailUsuario"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row" v-show="frmCadastrarConta.habilitarAcessoPainel">
          <div class="col-md-6">
            <b-form-group>
              <label>Celular:</label>
              <b-form-input
                name="celularUsuario"
                v-model="frmCadastrarConta.celularUsuario"
                data-inputmask="'mask': ['(99) 9999-9999', '(99) 99999-9999'], 'placeholder': ''"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Inputmask from "inputmask";

export default {
  props: {
    contaPrincipal: Object,
    variant: {
      type: String,
      default: "light-primary"
    },
    tipoBotao: {
      type: String,
      default: "texto"
    }
  },

  data() {
    return {
      frmCadastrarConta: {
        idContaPrincipal: null,
        sigla: "",
        login: "",
        senha: "",
        confirmarSenha: "",
        tipoPlano: "POS",
        limiteCredito: null,
        permitirMensagensLongas: false,
        habilitarAcessoPainel: false,
        nomeUsuario: "",
        emailUsuario: "",
        celularUsuario: ""
      },
      sltTipoPlano: [
        { text: "Pós-pago", value: "POS" },
        { text: "Pré-pago", value: "PRE" }
      ]
    };
  },

  computed: {
    ...mapGetters(["validatorErrors"]),

    botaoDropdown() {
      return this.tipoBotao === "dropdown";
    }
  },

  watch: {
    "frmCadastrarConta.habilitarAcessoPainel": function (value) {
      if (this.fv) {
        if (value) {
          this.fv.enableValidator("emailUsuario", "notEmpty");
          this.fv.enableValidator("emailUsuario", "emailAddress");
        } else {
          this.fv.disableValidator("emailUsuario", "notEmpty");
          this.fv.disableValidator("emailUsuario", "emailAddress");
        }
      }
    },

    "frmCadastrarConta.tipoPlano": function (value) {
      if (value == "POS") {
        this.fv.enableValidator("limiteCredito", "notEmpty");
      } else {
        this.fv.disableValidator("limiteCredito", "notEmpty");
      }
    },

    "frmCadastrarConta.idContaPrincipal": function (value) {
      if (this.fv) {
        if (value) {
          this.fv.disableValidator("sigla", "notEmpty");
          this.fv.disableValidator("sigla", "stringLength");
          this.fv.disableValidator("sigla", "regexp");
        }
      }
    }
  },

  methods: {
    limpar() {
      this.frmCadastrarConta = {
        idContaPrincipal: null,
        sigla: "",
        login: "",
        senha: "",
        confirmarSenha: "",
        tipoPlano: "POS",
        limiteCredito: null,
        permitirMensagensLongas: false,
        habilitarAcessoPainel: false,
        nomeUsuario: "",
        emailUsuario: "",
        celularUsuario: ""
      };
    },

    abrir() {
      this.limpar();

      if (this.contaPrincipal) {
        this.frmCadastrarConta.idContaPrincipal = this.contaPrincipal.id;
      }

      this.$refs["mdlCadastrarConta"].show();
    },

    validacao() {
      const frmCadastrarConta = this.$refs["frmCadastrarConta"];

      Inputmask().mask(frmCadastrarConta.querySelectorAll("input"));

      this.fv = formValidation(frmCadastrarConta, {
        fields: {
          sigla: {
            validators: {
              notEmpty: {
                enabled: true,
                message: "Campo obrigatório."
              },
              stringLength: {
                enabled: true,
                min: 3,
                max: 3,
                message:
                  "A sigla deve ter 3 caracteres contendo apenas letras e números."
              },
              regexp: {
                enabled: true,
                regexp: /^[a-zA-Z0-9]+$/i,
                message:
                  "A sigla deve ter 3 caracteres contendo apenas letras e números."
              },
              blank: {}
            }
          },
          login: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              stringLength: {
                min: 3,
                max: 36,
                message:
                  "O login deve ter de 3 a 36 caracteres contendo apenas letras e números."
              },
              regexp: {
                regexp: /^[a-zA-Z0-9]+$/i,
                message:
                  "O login deve ter de 3 a 36 caracteres contendo apenas letras e números."
              },
              blank: {}
            }
          },
          senha: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              regexp: {
                regexp: /^(?=.*[A-Za-z])(?=.*[0-9]).{12,20}$/,
                message:
                  "A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1 número."
              },
              blank: {}
            }
          },
          confirmarSenha: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              identical: {
                message:
                  'O campo "Senha" e "Confirmar Senha" devem ser iguais.',
                compare: function () {
                  return frmCadastrarConta.querySelector('[name="senha"]')
                    .value;
                }
              },
              blank: {}
            }
          },
          tipoPlano: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          limiteCredito: {
            validators: {
              notEmpty: {
                enabled: true,
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          nomeUsuario: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          emailUsuario: {
            validators: {
              notEmpty: {
                enabled: false,
                message: "Campo obrigatório."
              },
              emailAddress: {
                enabled: false,
                message: "O email informado é inválido."
              },
              blank: {}
            }
          },
          celularUsuario: {
            validators: {
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar(e) {
      e.preventDefault();

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("conta/salvar", this.frmCadastrarConta)
            .then((response) => {
              const conta = response.data.data;
              this.$emit("conta:cadastrada", conta);
              this.$refs["mdlCadastrarConta"].hide();

              Swal.fire({
                title: "MENSAGEM",
                text: "Cadastrado com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },

    usarSenha(senha) {
      this.frmCadastrarConta.senha = senha;
      this.frmCadastrarConta.confirmarSenha = senha;
    }
  }
};
</script>
