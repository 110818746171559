<template>
  <b-form ref="frmConta">
    <b-row>
      <b-col cols="4">
        <b-form-group>
          <label>Sigla<span class="text-danger">*</span>:</label>
          <b-form-input
            name="sigla"
            v-model="wizard.conta.sigla"
            size="lg"
            autofocus
            class="form-control-solid"
          ></b-form-input>
          <span class="form-text text-muted">Com 3 caracteres.</span>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <label>Login <span class="text-danger">*</span>:</label>
          <b-form-input
            name="login"
            v-model="wizard.conta.login"
            size="lg"
            class="form-control-solid"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group>
          <label>Senha <span class="text-danger">*</span>:</label>
          <b-form-input
            name="senha"
            v-model="wizard.conta.senha"
            type="password"
            autocomplete="new-password"
            size="lg"
            class="form-control-solid"
          ></b-form-input>
          <span class="form-text text-muted"
            >A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1
            número.</span
          >
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <label>Confirmar senha <span class="text-danger">*</span>:</label>
          <b-form-input
            name="confirmarSenha"
            v-model="wizard.conta.confirmarSenha"
            type="password"
            autocomplete="new-password"
            size="lg"
            class="form-control-solid"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col>
        <gerador-senha @usar-senha="usarSenha"></gerador-senha>
      </b-col>
    </b-row>

    <b-form-group>
      <div class="checkbox-inline">
        <label class="checkbox font-weight-normal">
          <input
            type="checkbox"
            name="possuiSubconta"
            v-model="wizard.conta.possuiSubconta"
          />
          <span></span>
          Possui subconta
        </label>
      </div>
    </b-form-group>
  </b-form>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

export default {
  props: {
    wizard: Object
  },

  mounted() {
    const frmConta = this.$refs["frmConta"];

    this.validator = formValidation(frmConta, {
      fields: {
        sigla: {
          validators: {
            notEmpty: {
              enabled: true,
              message: "Campo obrigatório."
            },
            stringLength: {
              enabled: true,
              min: 3,
              max: 3,
              message:
                "A sigla deve ter 3 caracteres contendo apenas letras e números."
            },
            regexp: {
              enabled: true,
              regexp: /^[a-zA-Z0-9]+$/i,
              message:
                "A sigla deve ter 3 caracteres contendo apenas letras e números."
            },
            blank: {}
          }
        },
        login: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            stringLength: {
              min: 3,
              max: 36,
              message:
                "O login deve ter de 3 a 36 caracteres contendo apenas letras e números."
            },
            regexp: {
              regexp: /^[a-zA-Z0-9]+$/i,
              message:
                "O login deve ter de 3 a 36 caracteres contendo apenas letras e números."
            },
            blank: {}
          }
        },
        senha: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            regexp: {
              regexp: /^(?=.*[A-Za-z])(?=.*[0-9]).{12,20}$/,
              message:
                "A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1 número."
            },
            blank: {}
          }
        },
        confirmarSenha: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            identical: {
              message: 'O campo "Senha" e "Confirmar Senha" devem ser iguais.',
              compare: function () {
                return frmConta.querySelector('[name="senha"]').value;
              }
            },
            blank: {}
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap()
      }
    });
  },

  methods: {
    usarSenha(senha) {
      this.wizard.conta.senha = senha;
      this.wizard.conta.confirmarSenha = senha;
    }
  }
};
</script>
