<template>
  <b-form ref="wizardModulo">
    <div class="d-flex justify-content-between">
      <p>Módulo Atende</p>
      <InputSwitch v-model="wizard.modulos.atende.ativo"></InputSwitch>
    </div>

    <div class="border-bottom mb-5 d-flex"></div>

    <div class="d-flex justify-content-between">
      <p>Módulo Agende</p>
      <InputSwitch v-model="wizard.modulos.agende.ativo"></InputSwitch>
    </div>
  </b-form>
</template>

<script>
import InputSwitch from "primevue/inputswitch";

export default {
  props: {
    wizard: Object
  },

  components: {
    InputSwitch
  }
};
</script>
