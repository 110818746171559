<template>
  <div>
    <modal-adicionar-usuario
      :idConta="conta.id"
      :unidades="unidades"
      :sltGrupo="sltGrupo"
      :grupos="grupos"
      :gruposPermissoes="gruposPermissoes"
      @usuario:cadastrado="usuarioCadastrado"
      v-if="isAdmin || hasPermission('gerenciar-usuario')"
    >
    </modal-adicionar-usuario>

    <b-table hover small show-empty :fields="cabecalho" :items="usuarios">
      <template #empty>
        <p class="text-center mb-0">Nenhum registro encontrado.</p>
      </template>

      <template #cell(actions)="row">
        <modal-alterar-senha
          :id="row.item.id"
          url="usuario/alterar-senha"
          titulo="Alterar Senha do Usuário"
          :exigirSenhaAtual="false"
        ></modal-alterar-senha>

        <modal-editar-usuario
          :usuario="row.item"
          :unidades="unidades"
          :sltGrupo="sltGrupo"
          :grupos="grupos"
          :gruposPermissoes="gruposPermissoes"
          @usuario:atualizado="usuarioAtualizado"
        ></modal-editar-usuario>

        <b-button
          variant="light-primary"
          size="sm"
          class="btn-icon"
          v-b-tooltip.noninteractive
          title="Excluir"
          @click="excluir(row.item.id, row.index)"
        >
          <i class="fas fa-trash"></i>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import ModalEditarUsuario from "./ModalEditarUsuario";
import ModalAdicionarUsuario from "./ModalAdicionarUsuario";
import ModalAlterarSenha from "@/view/components/ModalAlterarSenha";

export default {
  props: {
    conta: Object
  },

  data() {
    return {
      usuarios: [],
      unidades: [],
      sltGrupo: [],
      grupos: [],
      gruposPermissoes: {}
    };
  },

  components: {
    "modal-editar-usuario": ModalEditarUsuario,
    "modal-adicionar-usuario": ModalAdicionarUsuario,
    "modal-alterar-senha": ModalAlterarSenha
  },

  mounted() {
    this.abrir();
  },

  methods: {
    abrir() {
      ApiService.post("conta/usuarios", { idConta: this.conta.id }).then(
        (response) => {
          this.usuarios = response.data.data.usuarios;
          this.unidades = response.data.data.unidades;
          this.sltGrupo = response.data.data.sltGrupo;
          this.grupos = response.data.data.grupos;
          this.gruposPermissoes = response.data.data.gruposPermissoes;
        }
      );
    },

    usuarioCadastrado(usuarioCadastrado) {
      const index = this.usuarios.length;

      this.usuarios.splice(index, 0, usuarioCadastrado);
    },

    usuarioAtualizado(usuarioAtualizado) {
      const index = this.usuarios.findIndex(
        (usuario) => usuario.id === usuarioAtualizado.id
      );

      this.usuarios.splice(index, 1, usuarioAtualizado);
    },

    excluir(id, index) {
      const self = this;

      Swal.fire({
        title: "Deseja realmente excluir este usuário?",
        showCancelButton: true,
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.post("usuario/excluir", {
            idUsuario: id,
            idConta: this.conta.id
          }).then(() => {
            Swal.fire({
              title: "MENSAGEM",
              text: "Usuário excluído com sucesso!",
              icon: "success",
              heightAuto: false
            });

            self.usuarios.splice(index, 1);
          });
        }
      });
    }
  },

  computed: {
    ...mapGetters(["isAdmin", "hasPermission"]),

    cabecalho() {
      const cabecalho = [
        {
          key: "nome",
          label: "Nome",
          sortable: true,
          class: "align-middle"
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          class: "align-middle"
        },
        {
          key: "nome_grupo",
          label: "Grupo",
          sortable: true,
          class: "align-middle"
        }
      ];

      if (this.isAdmin || this.hasPermission("gerenciar-usuario")) {
        cabecalho.push({
          key: "actions",
          label: "Ações",
          sortable: false,
          class: "align-middle text-center"
        });
      }

      return cabecalho;
    }
  }
};
</script>
