var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{ref:"frmModuloAtende"},[_c('h6',[_vm._v("DADOS DA CONTA")]),_c('div',{staticClass:"separator separator-solid mb-3"}),_c('b-form-group',[_c('label',[_vm._v("Nome "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(":")]),_c('b-form-input',{staticClass:"form-control-solid",attrs:{"name":"contaNome","size":"lg"},model:{value:(_vm.wizard.modulos.atende.configuracoes.contaLiveChat.conta.nome),callback:function ($$v) {_vm.$set(_vm.wizard.modulos.atende.configuracoes.contaLiveChat.conta, "nome", $$v)},expression:"wizard.modulos.atende.configuracoes.contaLiveChat.conta.nome"}})],1),_c('b-row',[_c('b-col',[_c('b-form-group',[_c('label',[_vm._v("Qtd. Máx. Caixa de Entrada "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(":")]),_c('b-form-input',{staticClass:"form-control-solid",attrs:{"name":"contaQtdMaxCaixaEntrada","data-inputmask":"'alias': 'integer'","size":"lg"},model:{value:(
            _vm.wizard.modulos.atende.configuracoes.contaLiveChat.conta
              .qtdMaxCaixaEntrada
          ),callback:function ($$v) {_vm.$set(_vm.wizard.modulos.atende.configuracoes.contaLiveChat.conta
              , "qtdMaxCaixaEntrada", $$v)},expression:"\n            wizard.modulos.atende.configuracoes.contaLiveChat.conta\n              .qtdMaxCaixaEntrada\n          "}})],1)],1),_c('b-col',[_c('b-form-group',[_c('label',[_vm._v("Qtd. Máx. Usuários "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(":")]),_c('b-form-input',{staticClass:"form-control-solid",attrs:{"name":"contaQtdMaxUsuarios","data-inputmask":"'alias': 'integer'","size":"lg"},model:{value:(
            _vm.wizard.modulos.atende.configuracoes.contaLiveChat.conta
              .qtdMaxUsuarios
          ),callback:function ($$v) {_vm.$set(_vm.wizard.modulos.atende.configuracoes.contaLiveChat.conta
              , "qtdMaxUsuarios", $$v)},expression:"\n            wizard.modulos.atende.configuracoes.contaLiveChat.conta\n              .qtdMaxUsuarios\n          "}})],1)],1)],1),_c('h6',[_vm._v("DADOS DO USUÁRIO ADMINISTRADOR")]),_c('div',{staticClass:"separator separator-solid mb-3"}),_c('b-form-group',[_c('label',[_vm._v("Nome "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(":")]),_c('b-form-input',{staticClass:"form-control-solid",attrs:{"name":"usuarioNome","size":"lg"},model:{value:(_vm.wizard.modulos.atende.configuracoes.contaLiveChat.usuario.nome),callback:function ($$v) {_vm.$set(_vm.wizard.modulos.atende.configuracoes.contaLiveChat.usuario, "nome", $$v)},expression:"wizard.modulos.atende.configuracoes.contaLiveChat.usuario.nome"}})],1),_c('b-form-group',[_c('label',[_vm._v("Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(":")]),_c('b-form-input',{staticClass:"form-control-solid",attrs:{"name":"usuarioEmail","size":"lg"},model:{value:(
        _vm.wizard.modulos.atende.configuracoes.contaLiveChat.usuario.email
      ),callback:function ($$v) {_vm.$set(_vm.wizard.modulos.atende.configuracoes.contaLiveChat.usuario, "email", $$v)},expression:"\n        wizard.modulos.atende.configuracoes.contaLiveChat.usuario.email\n      "}})],1),_c('b-row',[_c('b-col',[_c('b-form-group',[_c('label',[_vm._v("Senha "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(":")]),_c('b-form-input',{staticClass:"form-control-solid",attrs:{"name":"usuarioSenha","type":"password","autocomplete":"new-password","size":"lg"},model:{value:(
            _vm.wizard.modulos.atende.configuracoes.contaLiveChat.usuario.senha
          ),callback:function ($$v) {_vm.$set(_vm.wizard.modulos.atende.configuracoes.contaLiveChat.usuario, "senha", $$v)},expression:"\n            wizard.modulos.atende.configuracoes.contaLiveChat.usuario.senha\n          "}}),_c('span',{staticClass:"form-text text-muted"},[_vm._v("A senha deve conter letras maiúsculas, minúsculas, número e um caractere especial.")])],1)],1),_c('b-col',[_c('b-form-group',[_c('label',[_vm._v("Confirmar Senha "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(":")]),_c('b-form-input',{staticClass:"form-control-solid",attrs:{"name":"usuarioConfirmarSenha","type":"password","autocomplete":"new-password","size":"lg"},model:{value:(
            _vm.wizard.modulos.atende.configuracoes.contaLiveChat.usuario
              .confirmarSenha
          ),callback:function ($$v) {_vm.$set(_vm.wizard.modulos.atende.configuracoes.contaLiveChat.usuario
              , "confirmarSenha", $$v)},expression:"\n            wizard.modulos.atende.configuracoes.contaLiveChat.usuario\n              .confirmarSenha\n          "}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('gerador-senha',{attrs:{"comprimento":10,"simbolos":true},on:{"usar-senha":_vm.usarSenha}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }