<template>
  <div class="d-inline-flex align-middle mr-2">
    <button
      type="button"
      class="btn btn-sm btn-primary font-weight-bold"
      @click.prevent="abrir"
    >
      <i class="fa fa-plus"></i> Novo Cliente
    </button>

    <b-modal
      ref="mdlWizard"
      title="Implantação de Novo Cliente"
      ok-title="Salvar"
      ok-variant="success"
      cancel-title="Cancelar"
      scrollable
      size="xl"
      ignore-enforce-focus-selector=".swal2-container"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <Wizard @wizard:finalizado="fecharModal"></Wizard>
    </b-modal>
  </div>
</template>

<script>
import Wizard from "./Wizard.vue";

export default {
  components: {
    Wizard
  },

  methods: {
    abrir() {
      this.$refs["mdlWizard"].show();
    },

    fecharModal() {
      this.$refs["mdlWizard"].hide();
    }
  }
};
</script>
