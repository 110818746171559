<template>
  <div :class="{ 'd-inline-flex align-middle mr-2': !botaoDropdown }">
    <template v-if="tipoBotao === 'texto'">
      <button
        type="button"
        class="btn btn-sm btn-primary font-weight-bold"
        @click.prevent="abrir"
      >
        <i class="fas fa-users"></i>Usuários
      </button>
    </template>
    <template v-else-if="tipoBotao == 'icone'">
      <b-button
        :variant="variant"
        size="sm"
        class="btn-icon"
        v-b-tooltip.noninteractive
        title="Usuários"
        @click="abrir"
      >
        <i class="fas fa-users"></i>
      </b-button>
    </template>
    <template v-else>
      <b-dropdown-item @click.prevent="abrir">Usuários</b-dropdown-item>
    </template>

    <b-modal
      ref="mdlUsuariosConta"
      title="Usuários da Conta"
      ok-title="Fechar"
      ok-variant="secondary"
      ok-only
      scrollable
      size="xl"
      ignore-enforce-focus-selector=".swal2-container"
      no-close-on-esc
      no-close-on-backdrop
      body-class="px-0 pb-2 pt-0"
      header-class="border-0"
    >
      <b-tabs
        v-model="abaSelecionada"
        content-class="p-4"
        lazy
        nav-wrapper-class="sticky-top bg-white"
      >
        <b-tab title="Comunicante" active>
          <tab-usuarios-comunicante
            :conta="conta"
            ref="abaUsuariosComunicante"
          ></tab-usuarios-comunicante>
        </b-tab>

        <b-tab title="Atende" v-if="exibirUsuariosLivechat">
          <tab-usuarios-chat
            :idConta="conta.id"
            ref="abaUsuariosChat"
          ></tab-usuarios-chat>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import UsuariosComunicante from "./usuario/UsuariosComunicante";
import UsuariosChat from "./livechat/UsuariosChat";
import ApiService from "@/core/services/api.service";

export default {
  props: {
    conta: Object,
    variant: {
      type: String,
      default: "light-primary"
    },
    tipoBotao: {
      type: String,
      default: "icone"
    }
  },

  data() {
    return {
      abaSelecionada: 0,
      recursos: []
    };
  },

  components: {
    "tab-usuarios-comunicante": UsuariosComunicante,
    "tab-usuarios-chat": UsuariosChat
  },

  computed: {
    botaoDropdown() {
      return this.tipoBotao === "dropdown";
    },

    exibirUsuariosLivechat() {
      return (
        this.conta.existeContaChatwoot == "S" &&
        this.recursos.includes("LIVE_CHAT")
      );
    }
  },

  methods: {
    async abrir() {
      await ApiService.get(`conta/recurso-ativo/${this.conta.id}`).then(
        (response) => {
          const recursos = response.data.data;

          this.recursos = recursos.map(function (obj) {
            return obj.rec_codigo;
          });
        }
      );

      this.$refs["mdlUsuariosConta"].show();
    }
  }
};
</script>
