<template>
  <div
    class="wizard wizard-2"
    id="wizardImplatacaoCliente"
    data-wizard-state="step-first"
    data-wizard-clickable="true"
  >
    <div class="wizard-nav border-right py-8 px-8">
      <div class="wizard-steps">
        <div
          class="wizard-step"
          data-wizard-type="step"
          data-wizard-state="current"
        >
          <div class="wizard-wrapper">
            <div class="wizard-icon">
              <i class="fas fa-person icon-2x"></i>
            </div>
            <div class="wizard-label">
              <h3 class="wizard-title">Cliente</h3>
              <div class="wizard-desc">Informe os dados do cliente</div>
            </div>
          </div>
        </div>

        <div class="wizard-step" data-wizard-type="step">
          <div class="wizard-wrapper">
            <div class="wizard-icon">
              <i class="fas fa-address-card icon-2x"></i>
            </div>
            <div class="wizard-label">
              <h3 class="wizard-title">Conta</h3>
              <div class="wizard-desc">Informe os dados da conta</div>
            </div>
          </div>
        </div>

        <div class="wizard-step" data-wizard-type="step">
          <div class="wizard-wrapper">
            <div class="wizard-icon">
              <i class="fas fa-tag icon-2x"></i>
            </div>
            <div class="wizard-label">
              <h3 class="wizard-title">Plano</h3>
              <div class="wizard-desc">Informe os dados do Plano</div>
            </div>
          </div>
        </div>

        <div class="wizard-step" data-wizard-type="step">
          <div class="wizard-wrapper">
            <div class="wizard-icon">
              <i class="fas fa-user icon-2x"></i>
            </div>
            <div class="wizard-label">
              <h3 class="wizard-title">Usuário</h3>
              <div class="wizard-desc">Informe os dados do usuário</div>
            </div>
          </div>
        </div>

        <div class="wizard-step" href="#" data-wizard-type="step">
          <div class="wizard-wrapper">
            <div class="wizard-icon">
              <i class="fas fa-table-list icon-2x"></i>
            </div>
            <div class="wizard-label">
              <h3 class="wizard-title">Módulos</h3>
              <div class="wizard-desc">Selecione os módulos ativos</div>
            </div>
          </div>
        </div>

        <div
          class="wizard-step"
          href="#"
          data-wizard-type="step"
          v-if="moduloAtendeAtivo"
        >
          <div class="wizard-wrapper">
            <div class="wizard-icon">
              <i class="fas fa-message icon-2x"></i>
            </div>
            <div class="wizard-label">
              <h3 class="wizard-title">Módulo Atende</h3>
              <div class="wizard-desc">Configurações do Atende</div>
            </div>
          </div>
        </div>

        <div
          class="wizard-step"
          href="#"
          data-wizard-type="step"
          v-if="moduloAgendeAtivo"
        >
          <div class="wizard-wrapper">
            <div class="wizard-icon">
              <i class="fas fa-calendar icon-2x"></i>
            </div>
            <div class="wizard-label">
              <h3 class="wizard-title">Módulo Agende</h3>
              <div class="wizard-desc">Configurações do Agende</div>
            </div>
          </div>
        </div>

        <div
          class="wizard-step"
          href="#"
          data-wizard-type="step"
          v-if="wizard.conta.possuiSubconta"
        >
          <div class="wizard-wrapper">
            <div class="wizard-icon">
              <i class="fas fa-address-card icon-2x"></i>
            </div>
            <div class="wizard-label">
              <h3 class="wizard-title">Subconta</h3>
              <div class="wizard-desc">Informações de subconta</div>
            </div>
          </div>
        </div>

        <div class="wizard-step" data-wizard-type="step">
          <div class="wizard-wrapper">
            <div class="wizard-icon">
              <i class="fas fa-thumbs-up icon-2x"></i>
            </div>
            <div class="wizard-label">
              <h3 class="wizard-title">Concluído!</h3>
              <div class="wizard-desc">Revisar as informações e salvar</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wizard-body py-8 px-8">
      <div class="row">
        <div class="col-12">
          <form class="form" id="kt_form">
            <div
              class="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <h4 class="mb-10 font-weight-bold text-dark">
                Informe os dados do cliente
              </h4>

              <Cliente
                ref="wizardCliente"
                :wizard="wizard"
                :tiposNegocio="tiposNegocio"
              ></Cliente>
            </div>

            <div class="pb-5" data-wizard-type="step-content">
              <h4 class="mb-10 font-weight-bold text-dark">
                Informe os dados da conta
              </h4>

              <Conta ref="wizardConta" :wizard="wizard"></Conta>
            </div>

            <div class="pb-5" data-wizard-type="step-content">
              <h4 class="mb-10 font-weight-bold text-dark">
                Informe os dados do plano
              </h4>

              <Plano
                ref="wizardPlano"
                :wizard="wizard"
                :tiposPlano="tiposPlano"
              ></Plano>
            </div>

            <div class="pb-5" data-wizard-type="step-content">
              <h4 class="mb-10 font-weight-bold text-dark">
                Informe os dados do usuário
              </h4>

              <Usuario ref="wizardUsuario" :wizard="wizard"></Usuario>
            </div>

            <div class="pb-5" data-wizard-type="step-content">
              <h4 class="mb-10 font-weight-bold text-dark">
                Selecione os módulos ativos
              </h4>

              <Modulo ref="wizardModulo" :wizard="wizard"></Modulo>
            </div>

            <div
              class="pb-5"
              data-wizard-type="step-content"
              v-if="moduloAtendeAtivo"
            >
              <h4 class="mb-10 font-weight-bold text-dark">
                Configurações do Atende
              </h4>

              <Atende ref="wizardModuloAtende" :wizard="wizard"></Atende>
            </div>

            <div
              class="pb-5"
              data-wizard-type="step-content"
              v-if="moduloAgendeAtivo"
            >
              <h4 class="mb-10 font-weight-bold text-dark">
                Configurações do Agende
              </h4>

              <Agende ref="wizardModuloAgende" :wizard="wizard"></Agende>
            </div>

            <div
              class="pb-5"
              data-wizard-type="step-content"
              v-if="wizard.conta.possuiSubconta"
            >
              <h4 class="mb-10 font-weight-bold text-dark">
                Informe os dados de subconta
              </h4>

              <Subconta ref="wizardSubconta" :wizard="wizard"></Subconta>
            </div>

            <div class="pb-5" data-wizard-type="step-content">
              <h4 class="mb-10 font-weight-bold text-dark">
                Revisar as informações e salvar
              </h4>

              <Informacoes
                :wizard="wizard"
                :moduloAtendeAtivo="moduloAtendeAtivo"
                :moduloAgendeAtivo="moduloAgendeAtivo"
                :tiposNegocio="tiposNegocio"
                :tiposPlano="tiposPlano"
              ></Informacoes>
            </div>

            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2">
                <button
                  class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-prev"
                >
                  Anterior
                </button>
              </div>
              <div>
                <button
                  @click.prevent="submit"
                  class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-submit"
                >
                  Salvar
                </button>
                <button
                  class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-next"
                >
                  Próximo
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<script>
import { mapGetters } from "vuex";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";

import Cliente from "./Cliente";
import Conta from "./Conta";
import Plano from "./Plano";
import Usuario from "./Usuario";
import Modulo from "./Modulo";
import Atende from "./Atende";
import Agende from "./Agende";
import Informacoes from "./Informacoes";
import Subconta from "./Subconta";

import ApiService from "@/core/services/api.service";
import Inputmask from "inputmask";

export default {
  name: "Wizard",

  components: {
    Cliente,
    Conta,
    Plano,
    Usuario,
    Modulo,
    Atende,
    Agende,
    Informacoes,
    Subconta
  },

  data() {
    return {
      wizard: {
        cliente: {
          nome: "",
          cep: "",
          franquiaMinima: 1000,
          tipoNegocio: null
        },
        conta: {
          sigla: "",
          login: "",
          senha: "",
          confirmarSenha: "",
          nome: "",
          idContaPrincipal: null,
          possuiSubconta: false
        },
        plano: {
          tipoPlano: "POS",
          limiteCredito: 1000,
          permitirMensagensLongas: "S",
          habilitarAcessoPainel: true
        },
        usuario: {
          nome: "",
          email: "",
          celular: "",
          senha: "",
          confirmarSenha: ""
        },
        modulos: {
          atende: {
            ativo: false,
            configuracoes: {
              contaLiveChat: {
                conta: {
                  nome: "",
                  qtdMaxCaixaEntrada: "",
                  qtdMaxUsuarios: ""
                },
                usuario: {
                  nome: "",
                  email: "",
                  senha: "",
                  confirmarSenha: ""
                }
              }
            }
          },
          agende: {
            ativo: false,
            configuracoes: {}
          }
        },
        subconta: []
      },
      tiposNegocio: [],
      tiposPlano: [
        { text: "Pós-pago", value: "POS" },
        { text: "Pré-pago", value: "PRE" }
      ],
      wizardElement: null,
      wizardRefs: [
        "wizardCliente",
        "wizardConta",
        "wizardPlano",
        "wizardUsuario",
        "wizardModulo"
      ]
    };
  },

  mounted() {
    this.startWizard();
    this.obterTipoNegocio();
  },

  methods: {
    startWizard(step = 1) {
      // Initialize form wizard
      this.wizardElement = new KTWizard("wizardImplatacaoCliente", {
        startStep: step, // initial active step number
        clickableSteps: true // allow step clicking
      });

      this.wizardElement.on("change", (wizardObj) => {
        if (wizardObj.getStep() > wizardObj.getNewStep()) {
          return;
        }

        const wizardRef = this.wizardRefs[wizardObj.getStep() - 1];
        const validator = this.$refs[wizardRef]?.validator;

        if (validator) {
          validator.validate().then((status) => {
            if (status == "Valid") {
              wizardObj.goTo(wizardObj.getNewStep());

              KTUtil.scrollTop();
            } else {
              Swal.fire({
                text: "Foram encontrados erros no formulário, por favor, verifique e tente novamente.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn font-weight-bold btn-light"
                }
              }).then(function () {
                KTUtil.scrollTop();
              });
            }
          });
        } else {
          wizardObj.goTo(wizardObj.getNewStep());

          KTUtil.scrollTop();
        }

        return false;
      });
    },

    obterTipoNegocio() {
      ApiService.get("conta/tipoNegocio")
        .then(async (response) => {
          this.tiposNegocio = [{ value: null, text: "Selecione" }].concat(
            response.data.data
          );
        })
        .catch((error) => {
          Swal.fire({
            title: "ERRO",
            text: "Houve um erro ao obter os tipos de negócio. Por favor, tente novamente.",
            icon: "error",
            heightAuto: false
          });
        });
    },

    stopWizard() {
      this.wizardElement.destroy();
    },

    submit(e) {
      e.preventDefault();

      ApiService.post("/conta/wizard/salvar", this.wizard)
        .then((res) => {
          this.$emit("wizard:finalizado");

          Swal.fire({
            title: "MENSAGEM",
            text: "Salvo com sucesso!",
            icon: "success",
            heightAuto: false
          });
        })
        .catch(() => {
          const errors = this.deepen(this.validatorErrors);

          if (errors.cliente) {
            this.exibirMensagemErro(
              this.$refs["wizardCliente"].validator,
              errors.cliente
            );
          }

          if (errors.conta) {
            this.exibirMensagemErro(
              this.$refs["wizardConta"].validator,
              errors.conta
            );
          }

          if (errors.plano) {
            this.exibirMensagemErro(
              this.$refs["wizardPlano"].validator,
              errors.plano
            );
          }

          if (errors.usuario) {
            this.exibirMensagemErro(
              this.$refs["wizardUsuario"].validator,
              errors.usuario
            );
          }

          if (errors.subconta) {
            this.exibirMensagemErro(
              this.$refs["wizardSubconta"].validator,
              errors.subconta,
              "subconta"
            );
          }
        });
    },

    deepen(obj) {
      const result = {};

      // For each object path (property key) in the object
      for (const objectPath in obj) {
        // Split path into component parts
        const parts = objectPath.split(".");

        // Create sub-objects along path as needed
        let target = result;
        while (parts.length > 1) {
          const part = parts.shift();
          target = target[part] = target[part] || {};
        }

        // Set value at end of path
        target[parts[0]] = obj[objectPath];
      }

      return result;
    },

    exibirMensagemErro(validator, errors, type) {
      for (const error in errors) {
        if (type) {
          for (const field in errors[error]) {
            let element = document.getElementsByName(
              `${type}[${error}].${field}`
            )[0];

            validator
              .updateValidatorOption(
                field,
                "blank",
                "message",
                errors[error][field]
              )
              .updateElementStatus(field, element, "Invalid", "blank");
          }
        } else {
          validator
            .updateValidatorOption(error, "blank", "message", errors[error])
            .updateFieldStatus(error, "Invalid", "blank");
        }
      }
    },

    atualizarWizard() {
      const step = this.wizardElement.getStep();
      this.stopWizard();
      this.$nextTick(() => {
        this.startWizard(step);
      });

      let wizardRefs = [
        "wizardCliente",
        "wizardConta",
        "wizardPlano",
        "wizardUsuario",
        "wizardModulo"
      ];

      if (this.wizard.modulos.atende.ativo) {
        wizardRefs.push("wizardModuloAtende");
      }

      if (this.wizard.modulos.agende.ativo) {
        wizardRefs.push("wizardModuloAgende");
      }

      if (this.wizard.conta.possuiSubconta) {
        wizardRefs.push("wizardSubconta");
      }

      this.wizardRefs = wizardRefs;
    }
  },

  computed: {
    moduloAtendeAtivo() {
      return this.wizard.modulos.atende.ativo;
    },

    moduloAgendeAtivo() {
      return this.wizard.modulos.agende.ativo;
    },

    ...mapGetters(["validatorErrors"])
  },

  watch: {
    "wizard.modulos.atende.ativo": {
      handler: function () {
        this.atualizarWizard();
      }
    },
    "wizard.modulos.agende.ativo": {
      handler: function () {
        this.atualizarWizard();
      }
    },
    "wizard.conta.possuiSubconta": {
      handler: function () {
        this.atualizarWizard();
      }
    }
  }
};
</script>
