<template>
  <div>
    <b-form ref="frmSubconta">
      <b-button @click="adicionar" variant="primary">Adicionar</b-button>

      <b-table :items="wizard.subconta" :fields="fields">
        <template #cell(login)="row">
          <b-form-group class="mb-0">
            <b-form-input
              v-model="wizard.subconta[row.index].login"
              :name="`subconta[${row.index}].login`"
              size="lg"
              class="form-control-solid login"
            ></b-form-input>
          </b-form-group>
        </template>

        <template #cell(limite)="row">
          <b-form-group class="mb-0">
            <b-form-input
              v-model="wizard.subconta[row.index].limite"
              data-inputmask="'alias': 'integer'"
              :name="`subconta[${row.index}].limite`"
              size="lg"
              class="form-control-solid limite"
            ></b-form-input>
          </b-form-group>
        </template>

        <template #cell(painel)="row">
          <b-form-checkbox
            v-model="wizard.subconta[row.index].habilitarAcessoPainel"
            :name="`subconta[${row.index}].habilitarAcessoPainel`"
            size="lg"
          >
          </b-form-checkbox>
        </template>

        <template #cell(acoes)="row">
          <b-button
            @click="remover(row.index)"
            variant="danger"
            style="padding: 11.7px !important"
            >Remover</b-button
          >
        </template>
      </b-table>
    </b-form>
  </div>
</template>

<script>
import Inputmask from "inputmask";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

export default {
  props: {
    wizard: Object
  },

  data() {
    return {
      fields: [
        {
          key: "login",
          label: "Login"
        },
        {
          key: "limite",
          label: "Limite"
        },
        {
          key: "painel",
          label: "Acessa painel?",
          class: "text-center",
          tdClass: "align-middle"
        },
        {
          key: "acoes",
          label: "Ações"
        }
      ]
    };
  },

  mounted() {},

  methods: {
    adicionar() {
      this.wizard.subconta.push({
        login: "",
        limite: "",
        habilitarAcessoPainel: false
      });

      this.$nextTick(() => {
        const frmSubconta = this.$refs["frmSubconta"];
        const inputs = frmSubconta.querySelectorAll("input");
        Inputmask().mask(inputs);

        const input = inputs[inputs.length - 2];
        input.focus();

        this.validacao();
      });
    },

    remover(index) {
      this.wizard.subconta.splice(index, 1);
    },

    validacao() {
      const frmSubconta = this.$refs["frmSubconta"];

      if (this.validator) {
        this.validator.destroy();
      }

      this.validator = formValidation(frmSubconta, {
        fields: {
          login: {
            selector: ".login",
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          limite: {
            selector: ".limite",
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    }
  }
};
</script>
