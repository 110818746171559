<template>
  <div>
    <div class="card card-custom">
      <div class="card-header">
        <div class="card-title">
          <h5 class="card-label">Contas</h5>
        </div>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-secondary font-weight-bold mr-2"
            @click="exibirFiltros()"
          >
            <i class="fa fa-filter"></i> Filtros
          </button>

          <modal-enviar-manual
            :conta="contaPrincipal"
            tipoBotao="texto"
            v-if="!isAdmin && hasPermission('gerenciar-conta')"
          >
          </modal-enviar-manual>

          <modal-usuarios
            :conta="contaPrincipal"
            tipoBotao="texto"
            v-if="!isAdmin && hasPermission('listar-usuario')"
          ></modal-usuarios>

          <modal-wizard v-if="isAdmin"></modal-wizard>

          <modal-adicionar-conta
            @conta:cadastrada="contaCadastrada"
            :contaPrincipal="contaPrincipal"
            v-if="isAdmin || hasPermission('gerenciar-conta')"
          ></modal-adicionar-conta>
        </div>
      </div>
      <div class="card-body gerenciamento-conta">
        <gerenciar-conta
          :contas="contas"
          :paginacao="paginacao"
        ></gerenciar-conta>

        <b-modal
          ref="mdlFiltros"
          title="Filtros"
          @ok="obterContas"
          ok-title="Aplicar filtros"
          ok-variant="success"
          cancel-title="Fechar"
          scrollable
          ignore-enforce-focus-selector=".swal2-container"
        >
          <b-form ref="frmFiltros" @submit.prevent="obterContas">
            <div class="row">
              <div class="col-md-6">
                <b-form-group>
                  <label>ID Integração:</label>
                  <b-form-input
                    v-model="frmFiltros.idIntegracao"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group>
                  <label>Sigla:</label>
                  <b-form-input
                    v-model="frmFiltros.sigla"
                    autofocus
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <b-form-group>
                  <label>Conta:</label>
                  <b-form-input
                    v-model="frmFiltros.login"
                    autofocus
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group>
                  <label>Cliente:</label>
                  <b-form-input v-model="frmFiltros.cliente"></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <b-form-group>
                  <label>Nome Identificação:</label>
                  <b-form-input
                    v-model="frmFiltros.nomeIdentificacao"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group>
                  <label>Tipo de plano:</label>
                  <b-form-select
                    :options="sltTipoPlano"
                    v-model="frmFiltros.tipoPlano"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>

            <div class="row" v-if="isAdmin">
              <div class="col-md-12">
                <b-form-group>
                  <label>Máquina Senderzap:</label>
                  <b-form-select
                    :options="sltMaquinaSenderzap"
                    v-model="frmFiltros.maquinaSenderzap"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>

            <b-btn type="submit" class="d-none"></b-btn>
          </b-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

import ModalAdicionarConta from "./ModalAdicionarConta";
import ModalEnviarManual from "./ModalEnviarManual.vue";
import ModalUsuarios from "./ModalUsuarios.vue";
import ModalWizard from "./wizard/ModalWizard.vue";

export default {
  name: "conta",
  data() {
    return {
      contas: [],
      paginacao: {
        paginaAtual: 1,
        porPagina: 20,
        total: 0
      },
      contaPrincipal: {},
      frmFiltros: {
        siga: "",
        login: "",
        cliente: "",
        tipoPlano: null,
        page: 1,
        porPagina: 20,
        colunaOrdenacao: "login",
        direcaoOrdenacao: "asc",
        maquinaSenderzap: null,
        idIntegracao: null,
        nomeIdentificacao: ""
      },
      sltTipoPlano: [
        { text: "Todos", value: null },
        { text: "Pós-pago", value: "POS" },
        { text: "Pré-pago", value: "PRE" }
      ],
      sltMaquinaSenderzap: [
        { text: "Todas", value: null },
        { html: "Senderzap 0 C-3PO", value: "senderzap0" },
        { html: "Senderzap 1 Bumblebee", value: "senderzap1" }
      ]
    };
  },

  computed: {
    ...mapGetters(["validatorErrors", "isAdmin", "hasPermission"])
  },

  components: {
    "modal-adicionar-conta": ModalAdicionarConta,
    "modal-enviar-manual": ModalEnviarManual,
    "modal-usuarios": ModalUsuarios,
    "gerenciar-conta": () => import("./GerenciarConta"),
    "modal-wizard": ModalWizard
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Conta" }]);

    this.obterContas();
  },

  methods: {
    exibirFiltros() {
      this.$refs["mdlFiltros"].show();
    },

    obterContas() {
      this.$refs["mdlFiltros"].hide();

      ApiService.post("conta", this.frmFiltros).then((response) => {
        this.contas = response.data.data.contas;
        this.contaPrincipal = response.data.data.contaPrincipal;
        this.paginacao.total = this.contas.length;
      });
    },

    contaCadastrada(contaCadastrada) {
      this.contas.push(contaCadastrada);
    }
  }
};
</script>
