<template>
  <b-form ref="frmUsuario">
    <b-form-group>
      <label>Nome <span class="text-danger">*</span>:</label>
      <b-form-input
        name="nome"
        v-model="wizard.usuario.nome"
        size="lg"
        class="form-control-solid"
      ></b-form-input>
    </b-form-group>

    <b-form-group>
      <label>Email <span class="text-danger">*</span>:</label>
      <b-form-input
        name="email"
        v-model="wizard.usuario.email"
        size="lg"
        class="form-control-solid"
      ></b-form-input>
    </b-form-group>

    <b-form-group>
      <label>Celular:</label>
      <b-form-input
        name="celular"
        v-model="wizard.usuario.celular"
        data-inputmask="'mask': ['(99) 9999-9999', '(99) 99999-9999'], 'placeholder': ''"
        size="lg"
        class="form-control-solid"
      ></b-form-input>
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group>
          <label>Senha <span class="text-danger">*</span>:</label>
          <b-form-input
            name="senha"
            v-model="wizard.usuario.senha"
            type="password"
            autocomplete="new-password"
            size="lg"
            class="form-control-solid"
          ></b-form-input>
          <span class="form-text text-muted"
            >A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1
            número.</span
          >
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <label>Confirmar senha <span class="text-danger">*</span>:</label>
          <b-form-input
            name="confirmarSenha"
            v-model="wizard.usuario.confirmarSenha"
            type="password"
            autocomplete="new-password"
            size="lg"
            class="form-control-solid"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <gerador-senha @usar-senha="usarSenha"></gerador-senha>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Inputmask from "inputmask";

export default {
  props: {
    wizard: Object
  },

  mounted() {
    const frmUsuario = this.$refs["frmUsuario"];

    Inputmask().mask(frmUsuario.querySelectorAll("input"));

    this.validator = formValidation(frmUsuario, {
      fields: {
        nome: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        },
        email: {
          validators: {
            notEmpty: {
              enabled: false,
              message: "Campo obrigatório."
            },
            emailAddress: {
              enabled: false,
              message: "O email informado é inválido."
            },
            blank: {}
          }
        },
        senha: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            stringLength: {
              min: 12,
              max: 20,
              message:
                "A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1 número."
            },
            regexp: {
              regexp: /^(?=.*[A-Za-z])(?=.*[0-9]).{12,20}$/,
              message:
                "A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1 número."
            },
            blank: {}
          }
        },
        confirmarSenha: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            identical: {
              message: 'O campo "Senha" e "Confirmar Senha" devem ser iguais.',
              compare: function () {
                return this.wizard.usuario.senha;
              }
            },
            blank: {}
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap()
      }
    });
  },

  methods: {
    usarSenha(senha) {
      this.wizard.usuario.senha = senha;
      this.wizard.usuario.confirmarSenha = senha;
    }
  }
};
</script>
