<template>
  <b-form ref="frmCliente">
    <b-form-group>
      <label>Nome<span class="text-danger">*</span>:</label>
      <b-form-input
        name="nome"
        v-model="wizard.cliente.nome"
        size="lg"
        autofocus
        class="form-control-solid"
      ></b-form-input>
    </b-form-group>

    <b-form-group>
      <label>Email de contato<span class="text-danger">*</span>:</label>
      <b-form-input
        name="email"
        v-model="wizard.cliente.email"
        size="lg"
        class="form-control-solid"
      ></b-form-input>
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group>
          <label>Franquia mínima<span class="text-danger">*</span>:</label>
          <b-form-input
            name="franquiaMinima"
            v-model="wizard.cliente.franquiaMinima"
            data-inputmask="'alias': 'integer'"
            size="lg"
            class="form-control-solid"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <label>CEP<span class="text-danger">*</span></label>
          <b-form-input
            name="cep"
            v-model="wizard.cliente.cep"
            data-inputmask="'mask': '99999-999'"
            size="lg"
            class="form-control-solid"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group>
      <label>Tipo de negócio<span class="text-danger">*</span>:</label>
      <b-form-select
        name="tipoNegocio"
        :options="tiposNegocio"
        v-model="wizard.cliente.tipoNegocio"
        size="lg"
        class="form-control form-control-solid"
      ></b-form-select>
    </b-form-group>
  </b-form>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Inputmask from "inputmask";

export default {
  props: {
    wizard: Object,
    tiposNegocio: {
      type: Array,
      default: () => []
    }
  },

  mounted() {
    const frmCliente = this.$refs["frmCliente"];

    Inputmask().mask(frmCliente.querySelectorAll("input"));

    this.validator = formValidation(frmCliente, {
      fields: {
        nome: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        },
        franquiaMinima: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        },
        cep: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            regexp: {
              regexp: /^\d{5}-\d{3}$/,
              message: "O CEP deve estar no formato 99999-999"
            },
            blank: {}
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        },
        tipoNegocio: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap()
      }
    });
  }
};
</script>
