<template>
  <b-form ref="frmPlano">
    <b-row>
      <b-col>
        <b-form-group>
          <label>Tipo de plano <span class="text-danger">*</span>:</label>
          <b-form-select
            name="tipoPlano"
            :options="tiposPlano"
            v-model="wizard.plano.tipoPlano"
            size="lg"
            class="form-control form-control-solid"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col v-show="wizard.plano.tipoPlano == 'POS'">
        <b-form-group>
          <label
            >Limite mensal de SMS <span class="text-danger">*</span>:</label
          >
          <b-form-input
            name="limiteCredito"
            v-model="wizard.plano.limiteCredito"
            data-inputmask="'alias': 'integer'"
            size="lg"
            class="form-control-solid"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group>
      <b-form-checkbox
        v-model="wizard.plano.permitirMensagensLongas"
        name="permitirMensagensLongas"
        value="S"
        unchecked-value="N"
      >
        Permitir mensagens longas (acima de 160 caracteres)
      </b-form-checkbox>
    </b-form-group>

    <div
      class="alert alert-info text-left font-size-sm"
      role="alert"
      v-show="wizard.plano.tipoPlano == 'PRE'"
    >
      <i class="fas fa-info-circle text-white mr-2"></i>
      Nos planos pré-pagos é necessário adicionar créditos à conta após a
      criação da mesma. Isso poderá ser feito na tela de edição da conta.
    </div>
  </b-form>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Inputmask from "inputmask";

export default {
  props: {
    wizard: Object,
    tiposPlano: {
      type: Array,
      default: () => []
    }
  },

  mounted() {
    const frmPlano = this.$refs["frmPlano"];

    Inputmask().mask(frmPlano.querySelectorAll("input"));

    this.validator = formValidation(frmPlano, {
      fields: {
        tipoPlano: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        },
        limiteCredito: {
          validators: {
            notEmpty: {
              enabled: true,
              message: "Campo obrigatório."
            },
            blank: {}
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap()
      }
    });
  },

  watch: {
    "wizard.plano.tipoPlano": function (value) {
      if (value == "POS") {
        this.validator.enableValidator("limiteCredito", "notEmpty");
      } else {
        this.validator.disableValidator("limiteCredito", "notEmpty");
      }
    }
  }
};
</script>
