<template>
  <div>
    <div class="row">
      <div class="col-12">
        <button
          type="button"
          class="btn btn-sm btn-success font-weight-bold float-right"
          @click.prevent="abrir"
        >
          <i class="fa fa-plus"></i> Cadastrar Usuário
        </button>
      </div>
    </div>
    <b-modal
      ref="mdlCadastrarUsuario"
      title="Cadastrar Usuário"
      @hidden="limpar"
      @shown="validacao"
      @ok="salvar"
      ok-title="Salvar"
      ok-variant="success"
      cancel-title="Cancelar"
      size="lg"
      scrollable
      ignore-enforce-focus-selector=".swal2-container"
    >
      <b-form ref="frmCadastrarUsuario">
        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label>Email <span class="text-danger">*</span>:</label>
              <b-form-input
                name="email"
                v-model="frmCadastrarUsuario.email"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group>
              <label>Nome <span class="text-danger">*</span>:</label>
              <b-form-input
                name="nome"
                v-model="frmCadastrarUsuario.nome"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label>Celular:</label>
              <b-form-input
                name="celular"
                v-model="frmCadastrarUsuario.celular"
                data-inputmask="'mask': ['(99) 9999-9999', '(99) 99999-9999'], 'placeholder': ''"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group>
              <label>Grupo <span class="text-danger">*</span>:</label>
              <b-form-select
                name="grupo"
                :options="sltGrupo"
                v-model="frmCadastrarUsuario.grupo"
              ></b-form-select>
              <span class="form-text"
                ><modal-grupos-permissoes
                  :grupos="grupos"
                  :gruposPermissoes="gruposPermissoes"
                ></modal-grupos-permissoes
              ></span>
            </b-form-group>
          </div>
        </div>

        <b-form-group v-if="checarAcessoNps">
          <label>Unidades</label>
          <pv-multiselect
            class="form-control"
            optionLabel="text"
            emptyFilterMessage="Nenhum resultado"
            :filter="true"
            :options="sltUnidades"
            placeholder="Todos"
            v-model="defaultsSelect.unidades"
            @input="obterUnidades"
          >
          </pv-multiselect>
        </b-form-group>

        <b-form-group>
          <label>Receber Notificação <span class="text-danger">*</span>:</label>
          <b-form-select
            name="notificacao"
            :options="sltRecebeNotificacao"
            v-model="frmCadastrarUsuario.notificacao"
          ></b-form-select>
        </b-form-group>

        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label>Senha <span class="text-danger">*</span>:</label>
              <b-form-input
                v-model="frmCadastrarUsuario.senha"
                name="senha"
                type="password"
                autocomplete="new-password"
              ></b-form-input>
              <span class="form-text text-muted"
                >A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1
                número.</span
              >
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group>
              <label>Confirmar Senha <span class="text-danger">*</span>:</label>
              <b-form-input
                v-model="frmCadastrarUsuario.confirmarSenha"
                name="confirmarSenha"
                type="password"
                autocomplete="new-password"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-12">
            <gerador-senha @usar-senha="usarSenha"></gerador-senha>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Inputmask from "inputmask";
import ModalGruposPermissoes from "./ModalGruposPermissoes";

export default {
  props: {
    idConta: {
      type: Number,
      default: null
    },
    unidades: {
      type: Array,
      default: () => []
    },
    sltGrupo: {
      type: Array,
      default: () => []
    },
    grupos: {
      type: Array,
      default: () => []
    },
    gruposPermissoes: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      defaultsSelect: {
        unidades: [],
        notificacao: []
      },
      frmCadastrarUsuario: {
        idConta: null,
        email: "",
        nome: "",
        celular: "",
        senha: "",
        confirmarSenha: "",
        grupo: null,
        unidades: null,
        notificacao: 0
      },
      sltRecebeNotificacao: [
        {
          text: "Nenhuma",
          value: 0
        },
        {
          html: "Notificação de Saldo",
          value: 1
        },
        {
          html: "Notificação de Saldo e Status do Aparelho",
          value: 2
        },
        {
          html: "Notificação de Detratores NPS",
          value: 3
        }
      ]
    };
  },

  components: {
    "modal-grupos-permissoes": ModalGruposPermissoes
  },

  computed: {
    ...mapGetters(["validatorErrors"]),

    sltUnidades() {
      const unidades = [];

      for (const obj of this.unidades) {
        if (obj.und_codigo) {
          unidades.push({
            text: obj.und_nome,
            value: obj.unidade_id,
            und: obj.und_codigo
          });
        }
      }

      return unidades;
    },

    checarAcessoNps() {
      const grupo = this.frmCadastrarUsuario.grupo;

      if (!grupo || grupo === 1) {
        return false;
      }

      return this.gruposPermissoes["NPS"][grupo].permissao_ativa === 1;
    }
  },

  methods: {
    limpar() {
      this.defaultsSelect.unidades = [];
      this.defaultsSelect.notificacao = [];

      this.frmCadastrarUsuario.idConta = null;
      this.frmCadastrarUsuario.email = "";
      this.frmCadastrarUsuario.nome = "";
      this.frmCadastrarUsuario.celular = "";
      this.frmCadastrarUsuario.senha = "";
      this.frmCadastrarUsuario.confirmarSenha = "";
      this.frmCadastrarUsuario.grupo = null;
      this.frmCadastrarUsuario.unidades = null;
      this.frmCadastrarUsuario.notificacao = 0;
    },

    abrir() {
      this.limpar();

      this.frmCadastrarUsuario.idConta = this.idConta;
      this.frmCadastrarUsuario.grupo = 1;

      this.$refs["mdlCadastrarUsuario"].show();
    },

    validacao() {
      const frmCadastrarUsuario = this.$refs["frmCadastrarUsuario"];

      Inputmask().mask(frmCadastrarUsuario.querySelectorAll("input"));

      this.fv = formValidation(frmCadastrarUsuario, {
        fields: {
          email: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              emailAddress: {
                message: "O email informado é inválido."
              },
              blank: {}
            }
          },
          nome: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          celular: {
            validators: {
              blank: {}
            }
          },
          grupo: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          senha: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              stringLength: {
                min: 12,
                max: 20,
                message:
                  "A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1 número."
              },
              regexp: {
                regexp: /^(?=.*[A-Za-z])(?=.*[0-9]).{12,20}$/,
                message:
                  "A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1 número."
              },
              blank: {}
            }
          },
          confirmarSenha: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              identical: {
                message:
                  'O campo "Senha" e "Confirmar Senha" devem ser iguais.',
                compare: function () {
                  return this.frmCadastrarUsuario.senha;
                }
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar(e) {
      e.preventDefault();

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("usuario/salvar", this.frmCadastrarUsuario)
            .then((response) => {
              const usuario = response.data.data;
              this.$emit("usuario:cadastrado", usuario);
              this.$refs["mdlCadastrarUsuario"].hide();

              Swal.fire({
                title: "MENSAGEM",
                text: "Salvo com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },

    usarSenha(senha) {
      this.frmCadastrarUsuario.senha = senha;
      this.frmCadastrarUsuario.confirmarSenha = senha;
    },

    obterUnidades(arr) {
      this.frmCadastrarUsuario.unidades = arr.map((o) => {
        return { unidade_id: o.value, und_codigo: o.und };
      });
    }
  }
};
</script>
