<template>
  <div :class="{ 'd-inline-flex align-middle mr-2': acao != 'cadastrar' }">
    <template v-if="acao == 'cadastrar'">
      <div class="row">
        <div class="col-12">
          <button
            type="button"
            class="btn btn-sm btn-success font-weight-bold float-right"
            @click.prevent="abrir"
          >
            <i class="fa fa-plus"></i> Cadastrar Usuário
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <b-button
        variant="light-info"
        size="sm"
        class="btn-icon"
        v-b-tooltip.noninteractive
        title="Editar"
        @click.prevent="abrir"
      >
        <i class="fas fa-edit"></i>
      </b-button>
    </template>

    <b-modal
      ref="mdlFormularioUsuario"
      :title="
        acao == 'cadastrar'
          ? 'Cadastrar Usuario do Atende'
          : 'Editar Usuario do Atende'
      "
      @hidden="limpar"
      @shown="validacao"
      @ok="salvar"
      ok-title="Salvar"
      ok-variant="success"
      cancel-title="Cancelar"
      size="lg"
      scrollable
      ignore-enforce-focus-selector=".swal2-container"
    >
      <b-form ref="frmFormularioUsuario">
        <div class="row">
          <div class="col-12">
            <b-form-group>
              <label>Nome <span class="text-danger">*</span>:</label>
              <b-form-input
                name="nome"
                v-model="frmFormularioUsuario.nome"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label>Email <span class="text-danger">*</span>:</label>
              <b-form-input
                name="email"
                v-model="frmFormularioUsuario.email"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group>
              <label>Grupo <span class="text-danger">*</span>:</label>
              <b-form-select
                name="grupo"
                :options="sltGrupo"
                v-model="frmFormularioUsuario.grupo"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label
                >Senha
                <span class="text-danger" v-if="!usuario">*</span>:</label
              >
              <b-form-input
                v-model="frmFormularioUsuario.senha"
                name="senha"
                type="password"
                autocomplete="new-password"
              ></b-form-input>
              <span class="form-text text-muted"
                >A senha deve conter letras maiúsculas, minúsculas, número e um
                caractere especial.</span
              >
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group>
              <label
                >Confirmar Senha
                <span class="text-danger" v-if="!usuario">*</span>:</label
              >
              <b-form-input
                v-model="frmFormularioUsuario.confirmarSenha"
                name="confirmarSenha"
                type="password"
                autocomplete="new-password"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-12">
            <gerador-senha
              @usar-senha="usarSenha"
              :comprimento="10"
              :simbolos="true"
            ></gerador-senha>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Inputmask from "inputmask";

export default {
  props: {
    acao: {
      type: String,
      default: "cadastrar"
    },
    idConta: {
      type: Number,
      default: null
    },
    usuario: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      frmFormularioUsuario: {
        idConta: null,
        id: null,
        nome: "",
        email: "",
        grupo: "agent",
        senha: "",
        confirmarSenha: ""
      },
      sltGrupo: [
        {
          text: "Agente",
          value: "agent"
        },
        {
          text: "Administrador",
          value: "administrator"
        }
      ]
    };
  },

  computed: {
    ...mapGetters(["validatorErrors"])
  },

  methods: {
    limpar() {
      this.frmFormularioUsuario.idConta = null;
      this.frmFormularioUsuario.id = null;
      this.frmFormularioUsuario.nome = "";
      this.frmFormularioUsuario.email = "";
      this.frmFormularioUsuario.grupo = "agent";
      this.frmFormularioUsuario.senha = "";
      this.frmFormularioUsuario.confirmarSenha = "";
    },

    abrir() {
      this.limpar();

      this.frmFormularioUsuario.idConta = this.idConta;

      if (this.usuario) {
        this.frmFormularioUsuario.id = this.usuario.id;
        this.frmFormularioUsuario.nome = this.usuario.name;
        this.frmFormularioUsuario.email = this.usuario.email;
        this.frmFormularioUsuario.grupo = this.usuario.role;
      }

      this.$refs["mdlFormularioUsuario"].show();
    },

    validacao() {
      const frmFormularioUsuario = this.$refs["frmFormularioUsuario"];

      Inputmask().mask(frmFormularioUsuario.querySelectorAll("input"));

      this.fv = formValidation(frmFormularioUsuario, {
        fields: {
          email: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              emailAddress: {
                message: "O email informado é inválido."
              },
              blank: {}
            }
          },
          nome: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          grupo: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          senha: {
            validators: {
              notEmpty: {
                enabled: !this.usuario,
                message: "Campo obrigatório."
              },
              stringLength: {
                enabled: !this.usuario,
                min: 8,
                message:
                  "A senha deve ter no mínimo 8 caracteres e conter letras maiúsculas, minúsculas, número e um caractere especial."
              },
              regexp: {
                enabled: !this.usuario,
                regexp:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                message:
                  "A senha deve ter no mínimo 8 caracteres e conter letras maiúsculas, minúsculas, número e um caractere especial."
              },
              blank: {}
            }
          },
          confirmarSenha: {
            validators: {
              notEmpty: {
                enabled: !this.usuario,
                message: "Campo obrigatório."
              },
              identical: {
                enabled: !this.usuario,
                message:
                  'O campo "Senha" e "Confirmar Senha" devem ser iguais.',
                compare: function () {
                  return this.frmFormularioUsuario.senha;
                }
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar(e) {
      e.preventDefault();

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          if (!this.usuario) {
            this.novoUsuario();
          } else {
            this.atualizarUsuario();
          }
        }
      });
    },

    novoUsuario() {
      ApiService.post(
        "conta/live-chat/cadastrar-agente",
        this.frmFormularioUsuario
      )
        .then((response) => {
          const usuario = response.data.data;

          this.$emit("usuario-cadastrado", usuario);
          this.$refs["mdlFormularioUsuario"].hide();

          Swal.fire({
            title: "MENSAGEM",
            text: "Cadastrado com sucesso!",
            icon: "success",
            heightAuto: false
          });
        })
        .catch(() => {
          for (const error in this.validatorErrors) {
            this.fv
              .updateValidatorOption(
                error,
                "blank",
                "message",
                this.validatorErrors[error]
              )
              .updateFieldStatus(error, "Invalid", "blank");
          }
        });
    },

    atualizarUsuario() {
      ApiService.post(
        "conta/live-chat/atualizar-agente",
        this.frmFormularioUsuario
      )
        .then((response) => {
          const usuario = response.data.data;

          this.$emit("usuario-atualizado", usuario);
          this.$refs["mdlFormularioUsuario"].hide();

          Swal.fire({
            title: "MENSAGEM",
            text: "Atualizado com sucesso!",
            icon: "success",
            heightAuto: false
          });
        })
        .catch(() => {
          for (const error in this.validatorErrors) {
            this.fv
              .updateValidatorOption(
                error,
                "blank",
                "message",
                this.validatorErrors[error]
              )
              .updateFieldStatus(error, "Invalid", "blank");
          }
        });
    },

    usarSenha(senha) {
      this.frmFormularioUsuario.senha = senha;
      this.frmFormularioUsuario.confirmarSenha = senha;
    }
  }
};
</script>
